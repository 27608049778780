<i18n>
{
  "en": {
    "recommended_categories": "Popular Categories",
    "category_list": "All Categories"
  },
  "ja": {
    "recommended_categories": "おすすめカテゴリー",
    "category_list": "カテゴリー一覧"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <h2 class="is-primary">{{ $t('recommended_categories') }}</h2>

    <div v-for="category in categoryList" :key="category.id">
      <h3>{{ category.name[locale] }}</h3>
      <category-movies :num-movies="moviesPerTab" :show-category="category.name.ja" />
    </div>

    <div class="section">
      <div class="button-box is-centered">
        <router-link class="button is-outlined is-large is-rounded" to="/category/">{{ $t('category_list') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import CategoryMovies from './categoryMovies.vue';
import CustomLists from '@/assets/js/services/CustomLists';

export default {
  props: {
    customListId: String,
    moviesPerTab: {
      type: Number,
      default: 8,
    },
  },
  components: {
    'category-movies': CategoryMovies,
  },
  data() {
    return {
      categoryList: [],
      categoryMap: {},
    };
  },
  async mounted() {
    const customListSvc = new CustomLists();
    this.categoryList = await customListSvc.getList('categories', this.customListId);

    // hide section header in the home page if there are no categories set
    this.$emit('categoriesEval', (this.categoryList) ? true : false);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
