<i18n>
{
  "en": {
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming"
  },
  "ja": {
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信"
  }
}
</i18n>

<template>
<div class="top-category-container">
  <div class="grid is-movie is-card">
    <div class="grid-item" v-for="(movie, index) in movieList" :key="movie.MovieID">
      <router-link
        :to="`/movies/${movie.MovieID}/`"
        :data-category="showCategory"
        :data-movie-id="movie.MovieID"
        :data-movie-title="movie.Title"
        :data-actress-name="movie.Actor"
        class="entry">
        <div class="entry-media">
          <div class="media-thum">
            <img
              class="media-image"
              :src="`${movie.thumbnailSrc}`"
              alt="fpo"
              @error="useFallbackImage(index)"
              loading="lazy"
              v-sfw
            />
          </div>
        </div>
        <div class="entry-meta">
          <div v-if="movie.IsLimited" class="tag-status is-limited">{{ $t('limited_tag') }}</div>
          <div v-if="movie.IsExpired" class="tag-status is-expired">{{ $t('expired_tag') }}</div>
          <div v-if="movie.IsComingSoon" class="tag-status is-future">{{ $t('future_tag') }}</div>
          <div class="meta-data">{{ movie.Release }}</div>
          <!-- actress -->
          <!-- multiple actress names spacing -> jp: space / en: comma + space -->
          <!-- need 2 x '&#32;' to get 1 space -->
          <div class="meta-title">
            <span v-if="locale == 'ja'"><span v-for="(actress, index) in movie.ActressesJa" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesJa.length">&#32;&#32;</span></span></span>
            <span v-if="locale == 'en'"><span v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&#32;</span></span></span>
          </div>
          <!-- age -->
          <age-tag :movie="movie" />
        </div>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import AgeTag from '@/components/_common/ageTag.vue';

export default {
  props: {
    numMovies: Number,
    showCategory: {
      type: String,
      required: false,
    },
  },
  components: {
    'age-tag': AgeTag,
  },
  data() {
    return {
      movieList: [],
      categoriesList: [],
      posterFallbackTries: {},
    };
  },
  created() {
    this.getMovies(this.userTypeID);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    getMovies(userTypeID) {
      const bfAPI = new BifrostAPI();
      bfAPI.getCategories().then((categoryMap) => {
        Object.keys(categoryMap).forEach((categoryId) => {
          if (this.showCategory === categoryMap[categoryId].name.ja) {
            // backend can take an array of category IDs here, so forcing a single element array
            bfAPI.getCategoryList(0, this.numMovies, [parseInt(categoryId, 10)]).then((result) => {
              const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
              this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);

              // check actress age
              // hide age if there are more than 1 actress in actressList
              // only display age if it's set over '18' (prevent webmaster input error and ignore data of age'0' in db)
              for (let i = 0; i < this.movieList.length; i += 1) {
                if (this.movieList[i].ActressesList && Object.keys(this.movieList[i].ActressesList).length === 1) {
                  const firstActressID = Object.keys(this.movieList[i].ActressesList)[0];
                  if (this.movieList[i].ActressesList[firstActressID].Age
                      && this.movieList[i].ActressesList[firstActressID].Age >= 18) {
                    this.$set(this.movieList[i], 'actressAge', this.movieList[i].ActressesList[firstActressID].Age);
                  }
                }
              }
            });
          }
        });
      });
    },

    showActressAge(movieActressesList) {
      let showAge = false;
      if (movieActressesList && Object.keys(movieActressesList).length === 1) {
        // except ignore actresses with an age of 0 in the db; actually setting to 18 so in case of
        // a webmaster typo and puts '8' instead of '18', we don't end up advertising a movie for an
        // 8 year old
        const firstActressID = Object.keys(movieActressesList)[0];
        if (movieActressesList[firstActressID].Age
            && movieActressesList[firstActressID].Age >= 18) showAge = true;
      }
      return showAge;
    },

    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.movieList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movieList[index], 'thumbnailSrc', `/assets/sample/${this.movieList[index].MovieID}/str.jpg`);
        this.posterFallbackTries[this.movieList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing_square.png');
      }
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
    numMovies(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
